import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react'
import Mixpanel from 'mixpanel-browser'

const MixpanelContext = createContext()

export function MixpanelProvider({ token, children }) {
  const mixpanelRef = useRef()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    Mixpanel.init(token, {
      loaded: (m) => {
        mixpanelRef.current = m
        console.log(m)
        setIsLoading(false)
      },
    })
  }, [])

  return (
    <MixpanelContext.Provider
      value={{ isLoading, mixpanel: mixpanelRef.current }}
    >
      {children}
    </MixpanelContext.Provider>
  )
}

export function useMixpanel() {
  const context = useContext(MixpanelContext)
  if (!context) {
    throw new Error('useMixpanel must be used within a MixpanelProvider')
  }
  return context
}

import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Illustration from '../images/hero-illustration.svg'
import HeroImage from '../images/hero-image.png'
import { PopupButton, PopupWidget } from 'react-calendly'
import CalendlyPopupButton from '../components/CalendlyPopupButton'
import { useMixpanel } from '../components/Mixpanel'
import supabase from '../lib/supabaseClient'
import useLocalStorageState from '../hooks/useLocalStorageState'

function seedableRandom(seed) {
  const x = Math.sin(seed++) * 10000
  return x - Math.floor(x)
}

function Hero({ disableButtons, firstName, calendlyId }) {
  const [searchParams] = useSearchParams()
  const { isLoading, mixpanel } = useMixpanel()
  const patientId = searchParams.get('id')
  const providerId = searchParams.get('pid')
  const initialWaitcount = Math.floor(
    ((seedableRandom(patientId) * 13 * 13) % 8) + 1
  )
  const [joinedWaitlist, setJoinedWaitlist] = useLocalStorageState(
    'waitlistJoined_' + patientId,
    false
  )
  const [waitCount, setWaitCount] = useState(
    initialWaitcount + (joinedWaitlist ? 1 : 0)
  )

  const joinWaitlist = async () => {
    setWaitCount(initialWaitcount + 1)
    setJoinedWaitlist(true)
    mixpanel.track('Join Waitlist', { patientId, providerId })
    const { data, error } = await supabase
      .from('patientProviderWaitpool')
      .insert([{ patientId, providerId }])

    console.log('data  > ', data)
    console.log('error > ', error)
  }

  const leaveWaitlist = async () => {
    setWaitCount(initialWaitcount)
    setJoinedWaitlist(false)
    mixpanel.track('Leave Waitlist', { patientId, providerId })
    const { data, error } = await supabase
      .from('patientProviderWaitpool')
      .delete()
      .eq('providerId', providerId)
      .eq('patientId', patientId)

    console.log('data  > ', data)
    console.log('error > ', error)
  }

  return (
    <section className="relative">
      {/* Bg */}
      <div
        className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 bg-gradient-to-br from-blue-400 via-sky-500 to-blue-600 pointer-events-none -z-10"
        aria-hidden="true"
      />

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-36 md:pt-40 md:pb-20">
          {/* Hero content */}
          <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
            {/* Content */}
            <div className="md:w-[600px]">
              {/* Copy */}
              <h1
                className="h1 text-white mb-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Hi {firstName},
                <br />
                See a doctor &nbsp;
                <span className="relative inline-flex items-center justify-center">
                  <svg
                    className="absolute -z-10"
                    width="246"
                    height="76"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M55.224 10.087c-13.986 3.38-25.552 7.614-33.97 12.438-4.171 2.412-7.508 4.953-9.953 7.58-2.395 2.628-3.807 5.332-4.21 8.058-.266 1.99.075 3.985 1.02 5.955.922 1.973 2.37 3.919 4.327 5.819 7.028 6.749 20.696 12.657 39.108 16.904 18.475 4.28 40.791 6.693 63.89 6.91 20.527.186 40.83-1.353 58.737-4.452 11.396-1.964 21.73-4.463 30.631-7.407 8.905-2.941 16.508-6.232 22.611-9.788 3.663-2.222 4.978-1.73 3.59.491-1.13 1.509-2.83 2.971-5.067 4.357-3.235 1.976-7.254 3.82-11.962 5.49-4.686 1.628-9.745 3.15-15.139 4.553a273.749 273.749 0 0 1-17.309 3.752 339.58 339.58 0 0 1-19.111 2.822c-3.367.35-6.676.738-10.087 1.025-3.412.286-6.868.546-10.339.75-13.955.815-28.266.87-42.283.165-13.996-.735-27.452-2.236-39.729-4.435-14.867-2.672-27.78-6.263-37.927-10.548-10.21-4.343-17.115-9.34-20.204-14.618C.15 43.028-.38 40.095.268 37.176c.295-1.462.868-2.917 1.713-4.357.883-1.432 2.027-2.847 3.427-4.239 2.819-2.783 6.622-5.463 11.342-7.99 4.626-2.528 10.101-4.9 16.335-7.074C48.423 8.116 68.15 4.072 90.24 1.802A371.99 371.99 0 0 1 115.924.135c54.806-1.437 105.87 8.691 124.34 24.662 1.911 1.728 3.392 3.498 4.431 5.295 1.352 2.388 1.655 4.82.901 7.234-.223 1.092-1.189 2.158-2.836 3.127-.493.309-1.076.603-1.742.88-.916.272-1.27-.27-1.344-1.462-.074-1.193 0-3.05-.429-5.409-.722-3.525-3.213-6.994-7.384-10.284-4.32-3.334-10.299-6.44-17.723-9.206-7.488-2.813-16.364-5.247-26.304-7.211-9.952-1.996-20.87-3.493-32.344-4.434-17.147-1.405-35.144-1.505-52.444-.292-8.673.62-17.094 1.537-25.108 2.732-7.997 1.207-15.556 2.672-22.552 4.37l-.162-.05Z"
                      fill="#c4b5fd"
                      fillRule="nonzero"
                    />
                  </svg>
                  {' faster'}
                </span>
                <br />
                Schedule or join waitlist
              </h1>
              <p
                className="text-lg text-blue-200 mb-8"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <br className="hidden md:block" /> If you don't see a time that
                works, please join the waitlist and we'll notify you when the
                next slot opens up.
              </p>
              <p
                className="text-lg text-blue-200 mb-8"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <br className="hidden md:block" /> There are <b>{waitCount}</b>{' '}
                people waiting. Message us if you need help.
              </p>

              {/* Buttons */}
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4 mb-12 md:mb-0"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div>
                  {joinedWaitlist ? (
                    <button
                      className="btn-sm w-full inline-flex items-center text-slate-100 bg-red-600 hover:bg-red-700 group shadow-sm rounded-md disabled:opacity-50 disabled:bg-red-600 transition-all"
                      onClick={leaveWaitlist}
                      disabled={disableButtons}
                    >
                      Leave Virtual Waitlist
                    </button>
                  ) : (
                    <button
                      className="btn-sm w-full inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm rounded-md disabled:opacity-50 disabled:bg-slate-800 transition-all"
                      onClick={joinWaitlist}
                      disabled={disableButtons}
                    >
                      Join Virtual Waitlist
                    </button>
                  )}
                </div>
                <div>
                  <CalendlyPopupButton
                    className="btn-sm w-full inline-flex items-center text-slate-700 bg-gradient-to-tr from-neutral-50 via-neutral-100 to-neutral-200 hover:bg-blue-300 shadow-sm relative before:absolute before:inset-0 before:bg-blue-400 before:bg-opacity-60 before:-z-10 before:rounded-md rounded-md disabled:opacity-50 disabled:bg-slate-800"
                    url={`https://calendly.com/${calendlyId}`}
                    color="#00a2ff"
                    disabled={disableButtons}
                  >
                    Schedule Appointment
                  </CalendlyPopupButton>
                </div>
              </div>
            </div>

            {/* Image */}
            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[600px] md:top-0 -mb-12 md:-mt-12 md:mb-0">
              <div className="relative -ml-3 -mr-24 md:mx-0">
                <img
                  className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                  src={Illustration}
                  width="960"
                  height="960"
                  aria-hidden="true"
                />
                <img
                  src={HeroImage}
                  className="md:max-w-none"
                  width="420"
                  height="545"
                  alt="mochi health three women"
                  data-aos="fade-up"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

import React from 'react';

function Section01() {
  return (
    <section>
      <div className="pt-12 max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-28 md:pb-20">
          {/* Section header */}
          <div className="pb-12">
            <h2 className="h2">FAQs</h2>
          </div>
          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-12 md:grid-cols-3 md:-mx-9 md:gap-0 items-start md:max-w-none">
            
            {/* 1st item */}
            <div
              className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-slate-200 last:after:hidden"
              data-aos="fade-up"
            >
              <div className="mb-3">
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_105_1347)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.0862 42.3636C45.7872 41.7894 47.5115 39.1343 46.9374 36.4333C46.3632 33.7322 43.7081 32.008 41.0071 32.5821C38.306 33.1562 36.5818 35.8113 37.1559 38.5124C37.73 41.2134 40.3851 42.9377 43.0862 42.3636ZM43.502 44.3199C47.2835 43.5161 49.6974 39.799 48.8936 36.0174C48.0899 32.2359 44.3728 29.822 40.5912 30.6258C36.8097 31.4296 34.3958 35.1467 35.1996 38.9282C36.0034 42.7097 39.7205 45.1236 43.502 44.3199Z" fill="#2563EB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3189 34.0493C41.8592 33.9345 42.3902 34.2793 42.505 34.8195L42.9386 36.8597L43.7773 37.4044C44.2405 37.7052 44.3721 38.3245 44.0713 38.7877C43.7706 39.2508 43.1512 39.3825 42.688 39.0817L41.1546 38.0859L40.5487 35.2353C40.4339 34.6951 40.7787 34.1641 41.3189 34.0493Z" fill="#2563EB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0106 30.1012L17.0543 30.517L17.4701 32.4733L19.4264 32.0575L19.0106 30.1012ZM16.6385 28.5607C15.558 28.7904 14.8683 29.8524 15.098 30.9329L15.5138 32.8892C15.7435 33.9696 16.8055 34.6593 17.8859 34.4296L19.8422 34.0138C20.9227 33.7841 21.6124 32.7221 21.3827 31.6417L20.9669 29.6854C20.7372 28.6049 19.6752 27.9153 18.5947 28.1449L16.6385 28.5607Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8358 28.4379L24.8795 28.8537L25.2953 30.81L27.2516 30.3942L26.8358 28.4379ZM24.4636 26.8974C23.3832 27.1271 22.6935 28.1891 22.9232 29.2695L23.339 31.2258C23.5687 32.3063 24.6307 32.996 25.7111 32.7663L27.6674 32.3505C28.7479 32.1208 29.4375 31.0588 29.2079 29.9784L28.7921 28.0221C28.5624 26.9416 27.5004 26.2519 26.4199 26.4816L24.4636 26.8974Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6609 26.7746L32.7047 27.1904L33.1205 29.1467L35.0768 28.7308L34.6609 26.7746ZM32.2888 25.2341C31.2084 25.4637 30.5187 26.5257 30.7484 27.6062L31.1642 29.5625C31.3938 30.643 32.4558 31.3326 33.5363 31.103L35.4926 30.6871C36.5731 30.4575 37.2627 29.3955 37.0331 28.315L36.6172 26.3587C36.3876 25.2783 35.3256 24.5886 34.2451 24.8183L32.2888 25.2341Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6739 37.9263L18.7176 38.3421L19.1334 40.2984L21.0897 39.8826L20.6739 37.9263ZM18.3018 36.3858C17.2213 36.6155 16.5316 37.6775 16.7613 38.7579L17.1771 40.7142C17.4068 41.7947 18.4688 42.4844 19.5492 42.2547L21.5055 41.8389C22.586 41.6092 23.2757 40.5472 23.046 39.4668L22.6302 37.5105C22.4005 36.43 21.3385 35.7403 20.258 35.97L18.3018 36.3858Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4991 36.2631L26.5428 36.6789L26.9586 38.6352L28.9149 38.2194L28.4991 36.2631ZM26.1269 34.7226C25.0465 34.9523 24.3568 36.0143 24.5865 37.0947L25.0023 39.051C25.232 40.1315 26.294 40.8211 27.3744 40.5915L29.3307 40.1757C30.4112 39.946 31.1008 38.884 30.8712 37.8035L30.4554 35.8473C30.2257 34.7668 29.1637 34.0771 28.0832 34.3068L26.1269 34.7226Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8547 20.5891C10.7398 20.0488 11.0847 19.5178 11.6249 19.403L16.5156 18.3634L16.0998 16.4071L11.2091 17.4467C9.58843 17.7912 8.55387 19.3842 8.89836 21.0049L13.8882 44.4804C14.2327 46.1011 15.8258 47.1356 17.4464 46.7911L37.2585 42.5799C36.7208 42.0767 36.2595 41.4861 35.899 40.8242L17.0306 44.8348C16.4904 44.9497 15.9594 44.6048 15.8445 44.0646L10.8547 20.5891ZM39.6278 30.9031C39.9376 30.7895 40.2591 30.6964 40.5912 30.6258C40.9233 30.5552 41.2549 30.5095 41.5841 30.4872L38.2428 14.7675C37.8983 13.1469 36.3053 12.1123 34.6846 12.4568L31.7502 13.0806L32.166 15.0368L35.1004 14.4131C35.6407 14.2983 36.1717 14.6431 36.2865 15.1834L39.6278 30.9031ZM18.4719 17.9476L28.8036 15.7515L28.3878 13.7953L18.0561 15.9913L18.4719 17.9476Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7939 13.4963L18.0561 15.9912L18.6798 18.9257C18.7947 19.4659 18.4498 19.9969 17.9096 20.1117C17.3694 20.2266 16.8384 19.8817 16.7235 19.3415L16.0998 16.407L11.2091 17.4466C10.1286 17.6763 9.43895 18.7383 9.6686 19.8187L10.7082 24.7095C10.9378 25.7899 11.9998 26.4796 13.0803 26.2499L36.5558 21.2601C37.6363 21.0304 38.3258 19.9677 38.0961 18.8872L37.0569 13.9978C36.8272 12.9174 35.7651 12.2271 34.6846 12.4567L31.7502 13.0805L32.3739 16.0149C32.4887 16.5551 32.1439 17.0861 31.6037 17.201C31.0634 17.3158 30.5324 16.971 30.4176 16.4307L29.7939 13.4963Z" fill="#60A5FA"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.4903 20.6364L12.1021 26.4579L11.6863 24.5016L39.0744 18.6801L39.4903 20.6364Z" fill="#60A5FA"/>
                    <path d="M13.7277 14.8666C13.6129 14.3264 13.9577 13.7953 14.4979 13.6805C15.0382 13.5657 15.5692 13.9105 15.684 14.4508L16.5156 18.3633C16.6305 18.9036 16.2856 19.4346 15.7454 19.5494C15.2052 19.6642 14.6742 19.3194 14.5593 18.7792L13.7277 14.8666Z" fill="#60A5FA"/>
                    <path d="M27.4218 11.9558C27.307 11.4156 27.6518 10.8846 28.192 10.7697C28.7322 10.6549 29.2632 10.9998 29.3781 11.54L30.2097 15.4526C30.3246 15.9928 29.9797 16.5238 29.4395 16.6386C28.8993 16.7535 28.3683 16.4086 28.2534 15.8684L27.4218 11.9558Z" fill="#60A5FA"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_105_1347">
                      <rect width="48" height="48" fill="white" transform="translate(0.534546 10.5145) rotate(-12)"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>
              <h4 className="text-xl font-bold mb-1">What happens if I have an existing appointment?</h4>
              <p className="text-slate-500">
                After scheduling your new appointment, please cancel your old appointments.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-slate-200 last:after:hidden"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="mb-3">

                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.6184 29.9208C25.4014 29.1167 27.8141 25.4014 27.01 21.6184C26.2059 17.8354 22.4906 15.4226 18.7076 16.2267C14.9246 17.0308 12.5119 20.7462 13.316 24.5292C14.1201 28.3121 17.8354 30.7249 21.6184 29.9208Z" fill="#60A5FA"/>
                  <path d="M42.4099 21.9232C43.0417 24.8955 41.1459 27.8148 38.1736 28.4466C35.2013 29.0783 32.2821 27.1826 31.6503 24.2103C31.0185 21.2379 32.9143 18.3187 35.8866 17.6869C38.8589 17.0551 41.7781 18.9508 42.4099 21.9232Z" fill="#2563EB"/>
                  <path d="M22.0342 31.8771C24.7081 31.3088 29.2372 31.2162 32.4438 32.2665C33.8619 33.2055 34.8285 34.3525 35.1026 35.6423L36.2669 41.1199L11.8132 46.3177L10.6489 40.8401C9.76406 36.6771 17.7902 32.7792 22.0342 31.8771Z" fill="#60A5FA"/>
                  <path d="M50.9391 38.0012L38.2232 40.704L37.0589 35.2264C36.7644 33.8411 35.9932 32.6723 34.9568 31.7031C36.3925 31.0461 37.8405 30.562 38.9736 30.3211C42.6205 29.546 49.2315 29.9674 49.9689 33.4365L50.9391 38.0012Z" fill="#2563EB"/>
                </svg>

              </div>
              <h4 className="text-xl font-bold mb-1">How does the waitlist work?</h4>
              <p className="text-slate-500">
                We notify you when a slot opens up to see your doctor faster. Waitlist is ranked on first come basis. When you finish scheduling, you’re off the waitlist.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-slate-200 last:after:hidden"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="mb-3">
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.08526 28.7789C6.92902 23.3393 10.4014 17.9922 15.8411 16.836L23.5305 15.2016C28.9702 14.0453 34.3172 17.5177 35.4734 22.9574C36.6296 28.3971 33.1573 33.7442 27.7176 34.9004L21.9166 36.1334L22.9273 40.8883C22.9273 40.8883 10.6842 41.0058 8.08526 28.7789Z" fill="#60A5FA"/>
                  <path d="M30.568 36.0613C32.273 37.1198 34.3733 37.5467 36.4912 37.0965L39.3713 36.4843L40.1798 40.2883C40.1798 40.2883 50.5352 36.0993 48.4561 26.3178C47.5311 21.9661 43.2534 19.1881 38.9017 20.1131L36.7404 20.5725C37.0458 21.1785 37.279 21.8332 37.4267 22.5282C38.6315 28.1962 35.6482 33.7805 30.568 36.0613Z" fill="#2563EB"/>
                </svg>

              </div>
              <h4 className="text-xl font-bold mb-1">Why did I receive a notification?</h4>
              <p className="text-slate-500">
                Our goal is to increase access to care. An appointment opened up to see our doctor faster. If you don’t join the waitlist you will no longer receive more notifications this week.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section01;

import React, { useEffect, useState } from 'react'

import MochiHeader from '../partials/MochiHeader'
import MochiHero from '../partials/MochiHero'
import MochiSection01 from '../partials/MochiSection01'
import MochiFaqs from '../partials/MochiFaqs'
import Cta from '../partials/Cta'
import Footer from '../partials/Footer'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { PopupWidget } from 'react-calendly'
import fetchWithErrorHandling from '../utils/fetchWithErrorHandling'

// for all us state shortcodes: https://gist.github.com/JeffPaine/3083347
const calendlyIdByRegion = {
  // CA: 'lucastestacc',
  NJ: 'lucasdellabella',
  IN: 'indiana-test-account',
}

function Home() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const id = searchParams.get('id')
  const patientQuery = useQuery(['patient', id], () => {
    return fetchWithErrorHandling(
      `${import.meta.env.VITE_UNTETHER_API_URL}/patient?id=${id}`
    ).then((res) => {
      console.log(res)
      return res.json()
    })
  })

  const patient = patientQuery.data

  useEffect(() => {
    if (!patientQuery.isLoading) {
      console.log('patientQuery.data > ', patientQuery.data)
      console.error('patientQuery.error > ', patientQuery.error)
    }
  }, [patientQuery.isLoading])

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <MochiHeader />
      <main className="grow">
        {patientQuery.isLoading || patientQuery.error ? (
          <MochiHero disableButtons={true} firstName="" calendlyId="" />
        ) : (
          <MochiHero
            firstName={patient.firstName}
            calendlyId={calendlyIdByRegion[patient.region]}
          />
        )}
        <MochiSection01 />
      </main>
      <Footer />
    </div>
  )
}

export default Home

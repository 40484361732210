import React from 'react';
import { Link } from 'react-router-dom';

import Illustration from '../images/footer-illustration.svg';

function Footer() {
  return (
    <footer className="relative">
      {/* Bg */}
      <div className="absolute inset-0 bg-slate-50 -z-10" aria-hidden="true" />

      {/* Illustration */}
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10" aria-hidden="true">
        <img className="max-w-none opacity-10" src={Illustration} width="1940" height="381" />
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Blocks */}
        <div className="grid sm:grid-cols-12 lg:grid-cols-10 gap-8 py-8">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-2 lg:max-w-xs">
            {/* Logo */}
            <Link className="block" to="/" aria-label="Untether Labs">
                <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.923 1.11438C22.2684 1.20557 22.9106 1.45713 23.3502 1.67358C23.7898 1.89004 24.4418 2.32972 24.7993 2.65068C25.1568 2.97163 25.68 3.58327 25.9623 4.00964C26.2445 4.43613 26.6199 5.19608 26.7967 5.69839L27.1179 6.61171L27.1508 14.8556L27.1836 23.0997L26.7512 23.0189C26.5133 22.9745 25.9519 22.7955 25.5034 22.621C25.0551 22.4465 24.3242 22.0608 23.8794 21.7637C23.4346 21.4666 22.7603 20.8781 22.3809 20.456C22.0015 20.034 21.4915 19.3014 21.2476 18.8283C21.0036 18.3551 20.6459 17.4892 20.5278 17.165L20.1999 16.362V8.6478V0.933594L20.7475 0.941165C21.0487 0.945294 21.5777 1.0233 21.923 1.11438Z" fill="url(#paint0_radial_1019_1179)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.923 1.11438C22.2684 1.20557 22.9106 1.45713 23.3502 1.67358C23.7898 1.89004 24.4418 2.32972 24.7993 2.65068C25.1568 2.97163 25.68 3.58327 25.9623 4.00964C26.2445 4.43613 26.6199 5.19608 26.7967 5.69839L27.1179 6.61171L27.1508 14.8556L27.1836 23.0997L26.7512 23.0189C26.5133 22.9745 25.9519 22.7955 25.5034 22.621C25.0551 22.4465 24.3242 22.0608 23.8794 21.7637C23.4346 21.4666 22.7603 20.8781 22.3809 20.456C22.0015 20.034 21.4915 19.3014 21.2476 18.8283C21.0036 18.3551 20.6459 17.4892 20.5278 17.165L20.1999 16.362V8.6478V0.933594L20.7475 0.941165C21.0487 0.945294 21.5777 1.0233 21.923 1.11438Z" fill="url(#paint1_linear_1019_1179)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4404 8.63108C17.404 15.6468 17.3814 16.4259 17.1963 17.0505C17.0842 17.4291 16.8158 18.0931 16.5998 18.5263C16.3838 18.9594 15.9731 19.6193 15.6871 19.9927C15.4011 20.3661 14.8692 20.9208 14.5051 21.2253C14.1409 21.5298 13.5672 21.9371 13.2302 22.1307C12.8933 22.3241 12.2232 22.6152 11.7412 22.7775C11.2592 22.9399 10.7838 23.0728 10.6851 23.0728C10.5233 23.0728 10.5056 22.2798 10.5073 15.1291C10.509 8.6187 10.5393 7.04328 10.6755 6.39735C10.7669 5.96386 11.0351 5.21538 11.2714 4.73406C11.5728 4.12025 11.9402 3.61668 12.5018 3.04738C12.9423 2.60104 13.5937 2.07063 13.9493 1.86885C14.3051 1.66696 14.8926 1.39935 15.2551 1.27397C15.6176 1.14871 16.2666 1.01324 16.6974 0.973087L17.4805 0.899902L17.4404 8.63108Z" fill="url(#paint2_radial_1019_1179)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4404 8.63108C17.404 15.6468 17.3814 16.4259 17.1963 17.0505C17.0842 17.4291 16.8158 18.0931 16.5998 18.5263C16.3838 18.9594 15.9731 19.6193 15.6871 19.9927C15.4011 20.3661 14.8692 20.9208 14.5051 21.2253C14.1409 21.5298 13.5672 21.9371 13.2302 22.1307C12.8933 22.3241 12.2232 22.6152 11.7412 22.7775C11.2592 22.9399 10.7838 23.0728 10.6851 23.0728C10.5233 23.0728 10.5056 22.2798 10.5073 15.1291C10.509 8.6187 10.5393 7.04328 10.6755 6.39735C10.7669 5.96386 11.0351 5.21538 11.2714 4.73406C11.5728 4.12025 11.9402 3.61668 12.5018 3.04738C12.9423 2.60104 13.5937 2.07063 13.9493 1.86885C14.3051 1.66696 14.8926 1.39935 15.2551 1.27397C15.6176 1.14871 16.2666 1.01324 16.6974 0.973087L17.4805 0.899902L17.4404 8.63108Z" fill="url(#paint3_radial_1019_1179)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.51364 1.11438C2.85902 1.20557 3.52579 1.46883 3.99538 1.69951C4.6198 2.00636 5.0806 2.35266 5.71119 2.98884C6.34165 3.62513 6.68452 4.08971 6.98776 4.719C7.21576 5.19218 7.48407 5.95087 7.58397 6.40488C7.74039 7.11585 7.76562 8.33143 7.76562 15.1515C7.76562 22.2808 7.7477 23.0725 7.58592 23.0725C7.48716 23.0725 7.01185 22.9405 6.52981 22.7791C6.04777 22.6177 5.29479 22.2729 4.8566 22.0127C4.41828 21.7527 3.69899 21.1731 3.25816 20.7251C2.81723 20.2769 2.28415 19.6166 2.0735 19.2578C1.86285 18.899 1.56463 18.2922 1.41072 17.9093C1.2567 17.5264 1.05667 16.8236 0.965901 16.3473C0.833003 15.6492 0.801034 14.0723 0.801034 8.20754V0.933594L1.34336 0.941165C1.6417 0.945294 2.16827 1.0233 2.51364 1.11438Z" fill="url(#paint4_radial_1019_1179)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.51364 1.11438C2.85902 1.20557 3.52579 1.46883 3.99538 1.69951C4.6198 2.00636 5.0806 2.35266 5.71119 2.98884C6.34165 3.62513 6.68452 4.08971 6.98776 4.719C7.21576 5.19218 7.48407 5.95087 7.58397 6.40488C7.74039 7.11585 7.76562 8.33143 7.76562 15.1515C7.76562 22.2808 7.7477 23.0725 7.58592 23.0725C7.48716 23.0725 7.01185 22.9405 6.52981 22.7791C6.04777 22.6177 5.29479 22.2729 4.8566 22.0127C4.41828 21.7527 3.69899 21.1731 3.25816 20.7251C2.81723 20.2769 2.28415 19.6166 2.0735 19.2578C1.86285 18.899 1.56463 18.2922 1.41072 17.9093C1.2567 17.5264 1.05667 16.8236 0.965901 16.3473C0.833003 15.6492 0.801034 14.0723 0.801034 8.20754V0.933594L1.34336 0.941165C1.6417 0.945294 2.16827 1.0233 2.51364 1.11438Z" fill="url(#paint5_linear_1019_1179)"/>
                    <defs>
                        <radialGradient id="paint0_radial_1019_1179" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.6876 25.9799) rotate(-43.1672) scale(23.1035 22.3444)">
                            <stop stop-color="#B8E2FB"/>
                            <stop offset="0.335562" stop-color="#F2EFE8"/>
                            <stop offset="0.545301" stop-color="#F9DCDD"/>
                            <stop offset="0.731737" stop-color="#E1C1E5"/>
                            <stop offset="1" stop-color="#BDAFE3"/>
                        </radialGradient>
                        <linearGradient id="paint1_linear_1019_1179" x1="20.7865" y1="12.9173" x2="27.1872" y2="12.1335" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#6941C6"/>
                            <stop offset="1" stop-color="#6941C6" stop-opacity="0.15"/>
                        </linearGradient>
                        <radialGradient id="paint2_radial_1019_1179" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.3407 31.8583) rotate(-88.4518) scale(29.0096 28.0064)">
                            <stop stop-color="#B8E2FB"/>
                            <stop offset="0.335562" stop-color="#F2EFE8"/>
                            <stop offset="0.545301" stop-color="#F9DCDD"/>
                            <stop offset="0.731737" stop-color="#E1C1E5"/>
                            <stop offset="1" stop-color="#BDAFE3"/>
                        </radialGradient>
                        <radialGradient id="paint3_radial_1019_1179" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.1245 14.3544) rotate(93.7153) scale(20.1588 40.0114)">
                            <stop stop-color="#6941C6"/>
                            <stop offset="1" stop-color="#6941C6" stop-opacity="0.15"/>
                        </radialGradient>
                        <radialGradient id="paint4_radial_1019_1179" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.08 22.453) rotate(-135) scale(16.626 17.2948)">
                            <stop stop-color="#B8E2FB"/>
                            <stop offset="0.335562" stop-color="#F2EFE8"/>
                            <stop offset="0.545301" stop-color="#F9DCDD"/>
                            <stop offset="0.731737" stop-color="#E1C1E5"/>
                            <stop offset="1" stop-color="#BDAFE3"/>
                        </radialGradient>
                        <linearGradient id="paint5_linear_1019_1179" x1="7.85492" y1="11.611" x2="0.14697" y2="14.3514" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#6941C6"/>
                            <stop offset="1" stop-color="#6941C6" stop-opacity="0.15"/>
                        </linearGradient>
                    </defs>
                </svg>
            </Link>
          </div>
        </div>

        {/* Bottom area */}
        <div className="pb-4 md:pb-8">
          <div className="text-xs text-slate-500">
           Powered by{' '}
            <a className="font-medium underline hover:text-violet-500 transition duration-150 ease-in-out" href="https://untetherlabs.com">
              Untether Labs
            </a>{' '}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react'
import { PopupModal } from 'react-calendly'

const CalendlyPopupButton = ({ disabled, url, className, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <button
        className={className}
        style={{ display: 'block', margin: '0 auto' }}
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        {children}
      </button>
      <PopupModal
        url={url}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById('root')!}
      />
    </div>
  )
}

export default CalendlyPopupButton

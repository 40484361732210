/**
 * This file was generated by ChatGPT using the following prompt:
 W whats the code for a custom react hook that allows me to create pieces of react state that also write to local storage
 */

import { useState } from 'react'

function useLocalStorageState(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorageState

/***
 * You can then use the hook like this:
 *
 * const [name, setName] = useLocalStorageState('name', 'Bob');
 * This will create a piece of state called "name" with an initial value of "Bob" and also write it to local storage with the key "name".
 *
 * Note that this hook makes use of the window.localStorage API, which might not be available on all environments (e.g. server-side rendering or non-browser environments).
 */

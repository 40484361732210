import React, { useEffect } from 'react'
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom'

import 'aos/dist/aos.css'
import './css/style.css'

import AOS from 'aos'

import MochiHome from './pages/MochiHome'
import { useMixpanel } from './components/Mixpanel'

function App() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { isLoading, mixpanel } = useMixpanel()
  const userId = searchParams.get('id')

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
  }, [])

  useEffect(() => {
    if (!isLoading && userId) {
      mixpanel.identify(userId)
    }
  }, [isLoading])

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]) // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/" element={<MochiHome />} />
      </Routes>
    </>
  )
}

export default App

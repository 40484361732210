import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MixpanelProvider } from './components/Mixpanel'

const queryClient = new QueryClient()

console.log(import.meta.env)

// console.log('import.meta.env > ', import.meta.env)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MixpanelProvider token={import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN}>
        <Router>
          <App />
        </Router>
      </MixpanelProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

import React from 'react';
import { Link } from 'react-router-dom';
import Illustration from './../images/mochi logo full current white.png';

function Header() {
  return (
    <header className="absolute w-full z-30">
      <div className="pt-6 max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            {/* Illustration */}
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10" aria-hidden="true">
              <img className="max-w-none" src={Illustration} width="250" height="40" />
            </div>

            {/*<Link className="block" to="/" aria-label="Cruip">*/}
            {/*  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <g fillRule="nonzero" fill="none">*/}
            {/*      <g className="fill-blue-50" transform="translate(3 3)">*/}
            {/*        <circle cx="5" cy="5" r="5" />*/}
            {/*        <circle cx="19" cy="5" r="5" />*/}
            {/*        <circle cx="5" cy="19" r="5" />*/}
            {/*        <circle cx="19" cy="19" r="5" />*/}
            {/*      </g>*/}
            {/*      <g className="fill-sky-300">*/}
            {/*        <circle cx="15" cy="5" r="5" />*/}
            {/*        <circle cx="25" cy="15" r="5" />*/}
            {/*        <circle cx="15" cy="25" r="5" />*/}
            {/*        <circle cx="5" cy="15" r="5" />*/}
            {/*      </g>*/}
            {/*    </g>*/}
            {/*  </svg>*/}
            {/*</Link>*/}
          </div>

          {/* Desktop navigation */}
          <nav className="flex grow">
            {/* Desktop sign in links */}
            {/*<ul className="flex grow justify-end flex-wrap items-center">*/}
            {/*  <li className="ml-3">*/}
            {/*    <Link className="btn-sm inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm" to="/apply">*/}
            {/*      Get your card*/}
            {/*      <span className="tracking-normal text-sky-400 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">*/}
            {/*        <svg className="fill-current" width="12" height="10" xmlns="http://www.w3.org/2000/svg">*/}
            {/*          <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />*/}
            {/*        </svg>*/}
            {/*      </span>*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
